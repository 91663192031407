import React, { Component, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import { Trans } from 'react-i18next'
import Icon from './SimpleComponents/Icon'
import LbModal from './Modal/LbModal'
import i18n from '../../i18n'
import { getCurrencySign } from '../helpers/formatter'

class DebtDialog extends Component {
  constructor(props) {
    super(props)
    this.open = this.open.bind(this)
  }

  open() {
    this.modal.handleOpenModal()
  }

  render() {
    const { commissions } = this.props
    return (
      <LbModal
        ref={modal => {
          this.modal = modal
        }}
        header={i18n.t('Common.CommissionDebt')}
        style={{ content: { width: '500px', height: 'auto' } }}
      >
        <div className="lb-confirm-text">
          <Trans i18nKey="Common.CommissionHint" />
          <hr />
          {commissions &&
            commissions.map(commission => (
              <p key={commission.id}>
                {commission.description}: {commission.amountInGel}{' '}
                {getCurrencySign('GEL')}
              </p>
            ))}
        </div>
      </LbModal>
    )
  }
}

export const ProductDebtNotification = ({ productDetail }) => (
  <DebtNotification
    id={`debtNotif-ProductDebtNotification-${productDetail.accountId}`}
    className="ml-10 is-red"
    debtModal={false}
    Message={productDetail.alerts
      .filter(x => x.type === 'Card')
      .map((x, i) => (
        <p key={i}>{x.message}</p>
      ))}
    place={`account-info-debt-${productDetail.iban}`}
  />
)

class DebtNotification extends React.Component {
  constructor(props) {
    super(props)
    this.openDebtModal = this.openDebtModal.bind(this)
  }

  openDebtModal(e) {
    const { debtModal, redirect } = this.props
    if (redirect) {
      redirect(e)
    }
    if (debtModal) {
      this.modal.open()
    }
  }

  render() {
    const {
      id,
      message,
      Message,
      commissions,
      className,
      debtModal,
      position
    } = this.props
    return (
      <Fragment>
        <i
          id={`i-DebtNotification-${id}`}
          className={`icn icn-warning ml-5 ${className ? className : ''} ${
            debtModal ? 'pointer' : ''
          }`}
          data-tip
          onClick={this.openDebtModal}
          data-for={`div-place-DebtNotification-${id}`}
        >
          <Icon name="icn-warning" viewBox="0 0 16 16" />
        </i>
        <ReactTooltip
          id={`div-place-DebtNotification-${id}`}
          className="lb-tooltip  type-darker"
          type="dark"
          effect="solid"
          place={position ? position : 'top'}
        >
          <span className="fnt-low-m">
            {message}
            {Message && (
              <Fragment>
                <p>{Message}</p>
              </Fragment>
            )}
          </span>
        </ReactTooltip>
        <DebtDialog
          ref={modal => (this.modal = modal)}
          commissions={commissions}
        />
      </Fragment>
    )
  }
}

export default DebtNotification

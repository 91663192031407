import { parseInt } from 'lodash'

export function moveItem(items, from, shift) {
  const fromIndex = items.findIndex(block => block.name === from)
  //const toIndex = items.findIndex(block => block.name === to);

  const fromElem = items.splice(fromIndex, 1)

  if (fromElem.length < 1) {
    return
  }

  items.splice(fromIndex + shift, 0, fromElem[0])
  return items
}
export function mergeAccountsWithCards(accounts = [], cards = []) {
  let accs = accounts
  let barats = cards
  accs &&
    accs.map(item => {
      item.cards = []
      barats.map(card => {
        if (card.iban === item.iban) item.cards.push(card)
      })
    })
  return accs
}
export function updateObject(...args) {
  return args.reduce((res, a) => ({ ...res, ...a }), {})
}

function _updateObjectDeep(curState, props, payload) {
  let prop = props[0]
  if (props.length === 1) return updateObject(curState, { [prop]: payload })
  return updateObject(curState, {
    [prop]: _updateObjectDeep(curState[prop], props.slice(1), payload)
  })
}

export function updateObjectDeep(state, props, payload) {
  return _updateObjectDeep(state, props.split('.'), payload)
}

export function nullToArray(array) {
  if (!array) return []
  return array
}

export function setCookie(cname, cvalue, exdays) {
  let d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toGMTString()
  // domain=${config.serverConfig.domain};
  document.cookie = `${cname}=${cvalue};${expires};domain=${window.location.hostname};path=/`
}

export function getCookie(cname) {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function isEquivalent(a, b) {
  let aProps = Object.getOwnPropertyNames(a)
  let bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i]

    if (a[propName] !== b[propName]) {
      return false
    }
  }

  return true
}

export function isEmpty(a) {
  return isEquivalent({}, a)
}

export function getUrlWithQueryString(url, params) {
  let strObject = Object.keys(params)
    .map(k =>
      Array.isArray(params[k])
        ? params[k]
            .map(
              value => `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
            )
            .join('&')
        : `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    )
    .join('&')
  if (strObject) {
    return `${url}?${strObject}`
  }
  return url
}

// SOURCE: https://rosettacode.org/wiki/IBAN#JavaScript
export function ibanValidation(iban) {
  let ibanLen = {
    NO: 15,
    BE: 16,
    DK: 18,
    FI: 18,
    FO: 18,
    GL: 18,
    NL: 18,
    MK: 19,
    SI: 19,
    AT: 20,
    BA: 20,
    EE: 20,
    KZ: 20,
    LT: 20,
    LU: 20,
    CR: 21,
    CH: 21,
    HR: 21,
    LI: 21,
    LV: 21,
    BG: 22,
    BH: 22,
    DE: 22,
    GB: 22,
    GE: 22,
    IE: 22,
    ME: 22,
    RS: 22,
    AE: 23,
    GI: 23,
    IL: 23,
    AD: 24,
    CZ: 24,
    ES: 24,
    MD: 24,
    PK: 24,
    RO: 24,
    SA: 24,
    SE: 24,
    SK: 24,
    VG: 24,
    TN: 24,
    PT: 25,
    IS: 26,
    TR: 26,
    FR: 27,
    GR: 27,
    IT: 27,
    MC: 27,
    MR: 27,
    SM: 27,
    AL: 28,
    AZ: 28,
    CY: 28,
    DO: 28,
    GT: 28,
    HU: 28,
    LB: 28,
    PL: 28,
    BR: 29,
    PS: 29,
    KW: 30,
    MU: 30,
    MT: 31
  }

  iban = iban.replace(/\s/g, '')
  // max length 34
  if (iban.length > 34) return false
  if (!iban.match(/^[\dA-Z]+$/)) return false

  // check length depended on country format
  if (iban.length != ibanLen[iban.substr(0, 2)]) return false

  iban = iban.substr(4) + iban.substr(0, 4)

  let s = '',
    i = 0
  for (s, i; i < iban.length; i++) s += parseInt(iban.charAt(i), 36)

  // https://en.wikipedia.org/wiki/International_Bank_Account_Number#Modulo_operation_on_IBAN
  let m
  for (m = s.substr(0, 15) % 97, s = s.substr(15); s; s = s.substr(13))
    m = (m + s.substr(0, 13)) % 97

  return m === 1
}

export function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Splits array in to nested array
export const chunk_inefficient = (array, chunk_size) =>
  [].concat.apply(
    [],
    array.map((_, i) =>
      i % chunk_size ? [] : [array.slice(i, i + chunk_size)]
    )
  )

export const transferDescriptionValidation = description => {
  if (description.length < 1) {
    return 'Common.PleaseFillThisField'
  }
  // only georgian latin characters
  if (!/^[a-zA-Zა-ჰ0-9\s.,'\\|/"@;[\]#$%^&*()_+=!:?-]*$/.test(description)) {
    return 'Transfers.OnlyGeorgianLatinCharacters'
  }
  // numbers only not allowed
  if (/^\d+$/.test(description) && !/[a-zA-Zა-ჰ]/.test(description)) {
    return 'Transfers.NumbersOnlyNotAllowed'
  }
  // symbols only not allowed
  if (/^[^\w\s]+$/.test(description) && !/[a-zA-Zა-ჰ]/.test(description)) {
    return 'Transfers.SymbolsOnlyNotAllowed'
  }
  // min 3 characters
  if (
    !description
      .trim()
      .split(/\s+/)
      .some(word => word.replace(/[^a-zA-Zა-ჰ]/g, '').length >= 3)
  ) {
    return 'Transfers.WordMinThreeChars'
  }
  return null
}

export const insuranceProviderFiledToNested = arr => {
  const res = {}
  const parents = []
  const children = []

  // fill parent and child
  arr.forEach(curr => {
    if (curr.parentId === '0') {
      parents.push(curr)
    } else {
      children.push(curr)
    }
  })

  parents.forEach(pr => {
    res[pr.fieldName] = {
      ...pr,
      children: children.filter(ch => parseInt(ch.parentId) === pr.id)
    }
  })

  return res
}

export const findFavoriteAccount = acc =>
  acc.primary && acc.currency === 'GEL' && !acc.socialAccountRestriction
